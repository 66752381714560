import * as React from 'react';
import { validate } from '../utils/validations';
import { BQDropDown } from './Generic/bq-input';
import { useBQGetList } from './Generic/hooks';
let ouItems = []

export const setOU = (data) => {
    const ouItem = ouItems.find(item => item.id === data.organizationalUnitId);
    if (ouItem) {
        data.organizationalUnit = ouItem.organizationalUnit;
    }
}

export const OUSelectInput = ({
    label,
    source,
    defaultValue,
    useNameAsValue,
    readOnly,
    filterObject,
    setFilterObject,
    notRequired,
    onChange: baseOnChange,
    onReady: baseOnReady
}) => {
    const [ouList, setOUList] = React.useState()
    const listData = useBQGetList({ resource: 'organizationalUnits', pagination: { page: 1, perPage: 1000 } });
    React.useEffect(() => {
        if (listData?.data) {
            ouItems = listData.data.map(item => {
                return ({ ...item, name: item.organizationalUnit })
            })
        } else {
            ouItems = []
        }
        setOUList(ouItems.length ? [...ouItems] : undefined)
    }, [listData])

    return <BQDropDown
        label={label || 'Clinic'}
        source={source || 'organizationalUnitId'}
        loading={listData.isLoading}
        choices={ouList}
        validate={!notRequired ? validate : undefined}
        useNameAsValue={useNameAsValue}
        defaultValue={defaultValue}
        readOnly={readOnly}
        onChange={event => {
            const { selection } = event
            if (filterObject) {
                filterObject[source] = selection.name
                if (setFilterObject) {
                    setFilterObject({ ...filterObject })
                }
            }
            baseOnChange?.(event)
        }}
        onReady={baseOnReady}
    />
}