/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    TextField,
    Create,
    DataProviderContext,
    LinearProgress,
    useNotify,
    useRedirect,
    useResourceContext
} from 'react-admin';
import { validateEmailArray, validateString } from '../utils/validations';

import { bqCreateRedirection, editorGlobalProps } from '../utils/constants';
import { BQModelList, BQSection, BQToolbar } from './Generic/BQUI';
import { BQAutoCompleteArrayInput, BQLabelWithInput, BQSimpleForm, FunctionField } from './Generic/bq-form-components';
import { getDateTime, isNullOrEmpty, limitText } from '../utils/textUtils';
import { BQInput } from './Generic/bq-input';
import { useBQStyles } from '../themes';
import { getCachedMobileDeviceUsers, getFromCache } from '../utils/globals';
import { exportObjectToCSV } from '../utils/bq_export';
import { recentRecepients } from '../utils/globals';
import BQCRUDBuilder from './Generic/BQCRUDComponent';
import { useParams } from "react-router-dom";

const getStatusText = (status) => status == true ? 'Sent Successfully' : 'Failed'

const TextMessageList = (props) => {
    const classes = getFromCache('bqClasses')
    return <BQModelList
        disableEdit
        disableDelete
        customButton={{
            title: 'Resend',
            className: classes.sendButton
        }}
        exporter={content => {
            const mappedContent = content.map(item => {
                return {
                    Date: getDateTime(item.timestamp),
                    Receiver: item.receiver,
                    Message: item.message,
                    Status: getStatusText(item.status)
                }
            })
            exportObjectToCSV(mappedContent, 'text-messages')
        }}
    >
        <FunctionField label="Date" value={(item) => {
            recentRecepients[item.receiver] = Math.max(recentRecepients[item.receiver] || 0, item.timestamp)
            return getDateTime(item.timestamp)
        }} />
        <TextField source="receiver" />
        <FunctionField source="message" value={val => limitText(val, 128)} title={val => val.message} />
        <FunctionField source="status" value={val => getStatusText(val)} />
    </BQModelList>
}

const textMessageTransform = async (data) => {
    return {
        id: data?.id,
        receivers: data?.receivers?.filter(item => !!item),
        message: data.message
    }
}

const fieldNamesToWatch = ['message']

const TextMessageEditor = props => {
    const [mobileDeviceUsers, setMobileDeviceUsers] = React.useState()
    const [messageLength, setMessageLength] = React.useState(0)
    const dataProvider = React.useContext(DataProviderContext);

    const maxMessageLength = 1000

    const [formWatch, setFormWatch] = React.useState({})
    const [bqForm, setBQForm] = React.useState({})
    React.useEffect(() => {
        const watchObject = {}
        fieldNamesToWatch.forEach((name, i) => watchObject[name] = formWatch[i])
        setBQForm(watchObject)
    }, [formWatch])

    React.useEffect(() => {
        getCachedMobileDeviceUsers(dataProvider).then(cachedMobileDeviceUsers => {
            cachedMobileDeviceUsers
                .sort((a, b) => {
                    const aRecent = recentRecepients[a.email] || 0
                    const bRecent = recentRecepients[b.email] || 0
                    let sortValue = bRecent - aRecent
                    if (sortValue === 0) {
                        sortValue = a.email.localeCompare(b.email)
                    }
                    return sortValue
                })
            setMobileDeviceUsers(cachedMobileDeviceUsers)
        })
    }, [])

    React.useEffect(() => {
        setMessageLength(bqForm?.message?.length || 0)
    }, [bqForm])

    const { save, basePath } = props
    return <BQSimpleForm
        setWatch={{
            action: setFormWatch,
            name: fieldNamesToWatch
        }}
        prepareForm={({ formData, setValue }) => {
            formData.receivers === undefined && setValue('receivers', !isNullOrEmpty(formData.receiver) ? [formData.receiver] : [], { shouldDirty: false, shouldTouch: true })
        }}
        toolbar={<BQToolbar type="Text Message" labelSubmit="Send" />}
        save={(data, redirectTo, _a) =>
            save(data, redirectTo, {
                ...(_a || {}),
                onSuccess: (response) => {
                    if (response.data.hasSomeError) {
                        notify(
                            'The message was not sent to some of the recipient, see list for details.',
                            { autoHideDuration: 6000, type: 'warning' }
                        )
                    } else {
                        notify('Message was sent', { autoHideDuration: 6000, type: 'success' })
                    }
                    redirect(redirectTo, basePath, data.id, data);
                },
                onFailure: (error) => notify(
                    'Unfortunately the message was not sent. Please try again.',
                    { autoHideDuration: 6000, type: 'warning' }
                )
            })}
    >
        <BQSection title="Send Text Message" language={props.language}>
            {mobileDeviceUsers
                ? <BQAutoCompleteArrayInput
                    label="Send To"
                    source="receivers"
                    choices={mobileDeviceUsers}
                    optionText="email"
                    variant="outlined"
                    validate={validateEmailArray} />
                : <LinearProgress />}
            <BQInput
                label="Text message"
                source="message"
                validate={validateString}
                multiline
                maxLength={maxMessageLength}
                onChange={(event) => {
                    setMessageLength(event.target.value.length)
                }} />
            <BQLabelWithInput>
                <></>
                <span id="messageCharCounter">{`${messageLength} / ${maxMessageLength} characters`}</span>
            </BQLabelWithInput>
        </BQSection>
    </BQSimpleForm>
}

const TextMessageMutation = (isCreate, props) => {
    const { id } = useParams()
    const [bqRecord, setBQRecord] = React.useState()
    const resourceName = useResourceContext()
    const dataProvider = React.useContext(DataProviderContext);
    const notify = useNotify()
    const redirect = useRedirect()

    React.useEffect(() => {
        id && dataProvider.getOne(resourceName, { id }).then(response => {
            setBQRecord(response.data)
        })
    }, [])

    const mutationProps = { ...props, ...editorGlobalProps(), transform: (data) => textMessageTransform(data) }
    return (id && bqRecord || !id) ? <Create
        {...mutationProps}
        record={bqRecord}
        mutationOptions={{
            onSuccess: (response) => {
                console.log(response)
                if (response.hasSomeError) {
                    notify(
                        'The message was not sent to some of the recipient, see list for details.',
                        { autoHideDuration: 6000, type: 'error' }
                    )
                } else {
                    notify('Message was sent', { autoHideDuration: 6000, type: 'success' })
                }
                redirect(`/${resourceName}`);
            },
            onError: (error) => notify(
                'Unfortunately the message was not sent. Please try again.',
                { autoHideDuration: 6000, type: 'error' }
            )
        }}>
        <TextMessageEditor {...props} redirect={bqCreateRedirection} />
    </Create> : <LinearProgress />
}

const TextMessageCreate = (props) => TextMessageMutation(true, props)

const TextMessageEdit = (props) => TextMessageMutation(false, props)

export default BQCRUDBuilder({
    Create: TextMessageCreate,
    Edit: TextMessageEdit,
    List: TextMessageList
})