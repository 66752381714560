/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
    TextField,
    DataProviderContext,
    useList,
    ListContextProvider,
    Datagrid
} from 'react-admin';

import { FunctionField } from './Generic/bq-form-components';
import { charBallot, charCheck } from '../utils/constants';
import { BQProgress, BQSection } from './Generic/BQUI';
import { bqAuthData } from '../utils/bq-auth';
import { getDateTime, getEMFTimeString, isNullOrEmpty } from '../utils/textUtils';
import { Button } from '@material-ui/core';
import { BQExporter } from '../utils/bq_export';


let bqExporter = new BQExporter()

export const TreatmentsData = (props) => {
    const { record, yesMapping } = props
    const patientId = record?.id
    const dataProvider = React.useContext(DataProviderContext);
    const [treatmentsData, setTreatmentsData] = React.useState({
        isLoading: true
    })

    useEffect(() => {
        dataProvider.getList('questionnairesAnswersForPatient',
            {
                pagination: { perPage: 10000 },
                filter: patientId
            })
            .then(response => {
                setTreatmentsData(response?.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }, [])

    return treatmentsData?.isLoading ? <BQProgress /> : <QuestionnairesList
        data={treatmentsData}
        patientId={patientId}
        idNumber={record.idNumber}
        yesMapping={yesMapping?.map(item => item.toLowerCase())}
    />
}

const QuestionnairesList = (props) => {
    const { data, patientId, idNumber, yesMapping } = props
    const { subjectOrPatient } = bqAuthData
    bqExporter.setFileName(`${idNumber} Worksheets`)
    if (!data || !data.length) {
        return <div style={{ textAlign: 'center', padding: '32px' }}>No worksheets data for this patient</div>
    }

    const caregiverWorksheets = data.map((item, index) => {
        const caregiverWorksheet = item.worksheets.find(worksheet => worksheet.role === 'Caregiver')
        const { treatmentDate } = item
        const {
            userId,
            role,
            treatmentDateEDC,
            operatorPresent,
            answers,
            deviceEMFTime,
            treatmentEMFTime,
            isTreatmentCounted,
        } = caregiverWorksheet || {}

        return {
            id: `(${patientId})_${index + 1}`,
            treatmentDateEDC,
            treatmentDate,
            treatmentDateTimestamp: new Date(treatmentDate).getTime(),
            userId,
            isTreatmentCounted,
            deviceEMFTime,
            treatmentEMFTime,
            isTreatmentCounted,
            role,
            operatorPresent,
            answers
        }
    })

    const headers = [...caregiverWorksheets].sort((a, b) =>
        (b?.answers?.length || 0) - (a?.answers?.length || 0))
        ?.[0]?.answers?.map(answer => answer.title)
        ?.sort((a, b) => {
            const aParts = a.split('.')
            const bParts = b.split('.')
            const aNumber = parseInt(aParts[0].split('Q')[1])
            const bNumber = parseInt(bParts[0].split('Q')[1])
            if (aNumber === bNumber) {
                if (aParts.length === 1) {
                    return -1
                }
                if (bParts.length === 1) {
                    return 1
                }
                return aParts[1].localeCompare(bParts[1])
            }
            return aNumber - bNumber
        })

    const exporterRef = bqExporter.createRefForExport(`${idNumber} - Worksheets`)

    const listContext = useList({
        data: caregiverWorksheets,
        ids: caregiverWorksheets?.map(item => item.id)
    })

    return caregiverWorksheets?.length ? (
        <BQSection title={`WorkSheets`} style={{ maxWidth: '100%', padding: '24px' }} headerPadding={0}>
            <Button variant="outlined" style={{ padding: 8, margin: '8px 0px' }} onClick={() => bqExporter.exportData(`${subjectOrPatient}s`)}>Export Worksheet</Button>
            <ListContextProvider value={listContext}>
                <Datagrid
                    style={{ width: 4000 }}
                    ref={exporterRef}
                    rowStyle={questionnaireRowStyle}
                    bulkActionButtons={false}>
                    <FunctionField label={`${subjectOrPatient} ID`} value={(v) => idNumber} />
                    <TextField label="Treatment Date - Subject timezone (EDC)" source="treatmentDateEDC" />
                    <FunctionField label="Treatment Date - Viewer timezone" source="treatmentDate" value={v => getDateTime(v)} />
                    <FunctionField label="Conducted by" source="userId" defaultValue="N/A" />
                    {headers?.map((header, index) => {
                        return <FunctionField
                            label={header || 'null'}
                            value={val => {
                                let fieldValue = val.answers?.find(item => item.title == header)?.answer
                                if (header.toLowerCase().indexOf('q5.') !== -1) {
                                    const q5Answer = val.answers?.find(item => item.title?.toLowerCase()?.startsWith('q5 '))?.answer
                                    if (!isNullOrEmpty(q5Answer) && yesMapping?.includes(q5Answer?.toLowerCase())) {
                                        fieldValue = null
                                    }
                                }
                                const displayValue = fieldValue || 'N/A'
                                const lineStyle = {
                                    textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '256px'
                                }
                                if (val.answers?.length && !fieldValue) {
                                    lineStyle.color = 'red'
                                    lineStyle.fontWeight = 'bold'
                                }
                                return <div style={lineStyle}>{displayValue}</div>
                            }}
                            title={val => {
                                const answerItem = val.answers?.[index]
                                return `${answerItem?.question || ''}\n\n${answerItem?.answer || ''}`.trim()
                            }} />
                    })}
                    <FunctionField label="EMF Duration" source="treatmentEMFTime" value={(treatmentEMFTime, record) => getEMFTimeString(treatmentEMFTime, record.deviceEMFTime)} />
                    <FunctionField label="Counted" source="isTreatmentCounted" value={(v) => v ? charCheck : charBallot} />
                    <FunctionField label="Missing" value={val => {
                        const emptyWorksheet = !val.answers?.length ?
                            (val.operatorPresent ? 'Operator Present' : 'Skipped')
                            : null

                        const answerRequired = val.answers?.some(item => JSON.parse(item.meta)?.answerRequired && !item?.answer?.length)

                        return (answerRequired && 'Missing') || emptyWorksheet || 'N/A'
                    }
                    } />
                </Datagrid >
            </ListContextProvider >
        </BQSection>
    ) : <></>
}

const questionnaireRowStyle = (row) => !row.operatorPresent && !row.answers?.length
    ? { backgroundColor: 'rgba(128, 0, 0, 0.2)' }
    : {};