/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from 'react';
import {
    TextField,
    Create,
    Edit,
    SelectField,
    DataProviderContext,
    AutocompleteArrayInput,
    LinearProgress,
    useRecordContext,
    useNotify
} from 'react-admin';

import { OUSelectInput, setOU } from './organizationUnitSelector.component';
import { getDuplicates, validate, validateDate, validateDuplicates, validateHeight, validateIdNumber, validateNumber, validatePhoneNumber, validateYearOfBirth } from '../utils/validations';
import { TreatmentProtocolComponent, ExerciseCategoryCheckbox, getLists } from './Generic/TreatmentProtocolExerciseData';
import { BQCheckbox, BQLabel, FunctionField, BQSimpleForm, BQSmartIterator, BQAutoCompleteArrayInput } from './Generic/bq-form-components';
import { bqCreateRedirection, bqEditRedirection, charCheck, editorGlobalProps } from '../utils/constants';
import { BQModelList, BQSection, BQSideBySide, BQToolbar, FormButton, PhaseSelector } from './Generic/BQUI';
import { bqAuthData } from '../utils/bq-auth';
import { bqBlue } from '../themes';
import { BQDropDown, BQInput } from './Generic/bq-input';
import { RefreshRounded } from '@material-ui/icons';
import { useFormContext } from 'react-hook-form';
import { getDateTime, getTranslatedName, getValueByPath, isNullOrEmpty } from '../utils/textUtils';
import { Button, CircularProgress } from '@material-ui/core';
import { startVideoMeeting } from '../utils/bq-zoom';
import { BQExporter } from '../utils/bq_export';
import { prepareFormData } from '../utils/transforms';
import BQCRUDBuilder from './Generic/BQCRUDComponent';
import { getFromCache } from '../utils/globals';
import { getInitialPhase } from './Generic/UITools';
import DraggableDialog from './Generic/ConfirmationDialog';
import { useBQGetOne } from './Generic/hooks';
import { BQDynamicInput } from './Generic/bq-filter-components';
import { TreatmentsData } from './worksheets.component';


let bqExporter = new BQExporter()

const yearOfBirthVerification = 'yearOfBirthVerification'

const confirmationObject = [
    {
        title: `[subjectOrPatient] [idNumber] is set as a [controlPatient] subject`,
        text: `Are you sure you want to set this [subjectOrPatient] as <b>[controlPatient]</b> subject?`,
        condition: (bqForm, record) => bqForm.controlPatient &&
            bqForm.controlPatient != "UNSET" &&
            (!record.idNumber || !record.controlPatient || record.controlPatient === "UNSET")
    },
    {
        title: `Treatment for [subjectOrPatient] [idNumber] is set to [treatmentType], [treatmentTypeVariant]`,
        text: `Are you sure you want to set this [subjectOrPatient] to be treated for <b>[treatmentType]</b>, [treatmentTypeVariant_label]: <b>[treatmentTypeVariant]</b>?`,
        condition: (bqForm, record, { selectedTreatmentType }) => bqForm.treatmentType && (!record.idNumber || !record.treatmentType) && selectedTreatmentType?.variants?.length
    },
    {
        title: `Treatment for [subjectOrPatient] [idNumber] is set to [treatmentType_label] - [treatmentType]`,
        text: `Are you sure you want to set this [subjectOrPatient] to be treated for [treatmentType_label]: <b>[treatmentType]</b>?`,
        condition: (bqForm, record, { selectedTreatmentType }) => bqForm.treatmentType && (!record.idNumber || !record.treatmentType) && !selectedTreatmentType?.variants?.length
    },
    {
        title: `Treatment Protocol Warning`,
        text: `We noticed that you don’t have exercises chosen for stage <b>[value]</b>. Are you sure you want to proceed?`,
        condition: (bqForm) => bqForm.currentTreatmentProtocol?.treatmentStages?.
            filter(stage => !stage.exerciseCategories?.some(cat => cat.selectedExercises.some(exercise => exercise.isEnabled)))
            ?.map(stage => stage.name).reduce((acc, stage) => `${acc}, ${stage}`, '')
    },
    {
        title: `[subjectOrPatient] has completed treatment / discontinued`,
        text: `Are you sure that you want to mark [subjectOrPatient] [idNumber] as <b>complete / discontinued</b>? This [subjectOrPatient] will no longer appear in the BQ app`,
        condition: (bqForm, record) => bqForm.participationConcluded && !record.participationConcluded
    }
]

const PatientList = (props) => {
    const { appSettings } = props
    const { Phases, gender, relation, yesMapping } = appSettings || {}
    const initialPhase = getInitialPhase(Phases)

    const { clinicType, isSuperAdmin, isAdmin, isOperator, isViewer, subjectOrPatient } = bqAuthData
    const isMarket = clinicType === 'MARKET'
    const roleOtherThanRandomizer = bqAuthData.role.some(role => role.indexOf('Admin') !== -1)

    const exporterRef = bqExporter.createRefForExport(`${subjectOrPatient}s`)

    const dataProvider = useContext(DataProviderContext);
    const [state, setState] = useState({ isLoadingDictionaries: true });

    useEffect(() => {
        getLists(dataProvider, true).then(data => setTimeout(() => setState({
            isLoadingDictionaries: false,
            cache: data
        }), 500))
    }, [dataProvider]);

    return (
        !!gender && <BQModelList
            dataGridRef={exporterRef}
            disableDelete={true}
            nameField="idNumber"
            showDates={true}
            expand={(isAdmin || isOperator || isViewer) && <TreatmentsData yesMapping={yesMapping} /> || null}
            expandSingle={false}
            exporter={() => bqExporter.exportData(`Patients`, true)}
        >
            <TextField label={`${isMarket ? 'Patient name' : 'Subject ID'}`} source="idNumber" />
            {roleOtherThanRandomizer && <SelectField label="Sex" source="gender" choices={gender || []} />}
            <TextField source="organizationalUnit" label="Clinic" />
            {roleOtherThanRandomizer && (
                state?.cache && <FunctionField
                    label="Treatment protocol"
                    source="treatmentProtocolId"
                    value={(val) => {
                        return state.cache.treatmentProtocolsCache.find(item => item.id == val)?.name || 'N/A'
                    }} /> || <LinearProgress />)
            }
            {Phases && <FunctionField source="phase" label="Clinical study stage" value={v => (Phases?.find(item => item.id === v) || initialPhase)?.name} />}
            {roleOtherThanRandomizer && <SelectField source="caregiverRelation" label="Nature of caregiver relationship" choices={relation || []} />}
            {!isMarket && <FunctionField label="Experimental group selected" source="controlPatient" value={(v) => v?.toLowerCase() !== 'unset' ? charCheck : ''} />}
            <FunctionField label="Completed / Discontinued" source="participationConcluded" value={(v) => !!v ? charCheck : ''} />
        </BQModelList>
    )
}

const PatientEditor = (props) => {
    const { isSuperAdmin, isAdmin, isRandomizer, isAuditor, isOperator, role } = bqAuthData
    const [patientType, setPatientType] = useState()
    const subjectOrPatient = patientType !== 'RESEARCH' ? 'Patient' : 'Subject'
    const canEditTreatment = isAdmin || isOperator
    const { code: langCode, name: langName } = props.language || {}
    const isEnglish = !langCode || langCode.toLowerCase() === 'en'

    const notify = useNotify()
    const record = useRecordContext()
    const displayVideoButton = record.treatmentProtocolId && (record.controlPatient === 'SET' || patientType !== 'RESEARCH') && record.treatmentType && isOperator

    const roleOtherThanRandomizer = role.some(role => role.toLowerCase().indexOf('random') === -1)
    const displayTreatmentShamComponents = ((!record.idNumber || !record.controlPatient || record.controlPatient === 'UNSET') && isRandomizer)

    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: `A ${subjectOrPatient?.toLowerCase()}`,
        duplicates: props.duplicates
    }

    const { appSettings, cache } = props
    const { Phases,
        gender,
        deviceType,
        changesInDevice: changesInDeviceData,
        deficits,
        relation,
        controlPatientOptions,
        choicesTreatmentStageType
    } = appSettings || {}

    const initialPhase = getInitialPhase(Phases)
    const isAssessmentScoresReadOnly = !isAdmin && !isOperator

    const [ouData, setOUData] = useState(isSuperAdmin ? {} : props.ouData)
    const [changesInDeviceOptions, setChangesInDeviceOptions] = useState()
    const [currentPhase, setCurrentPhase] = useState()
    const [phaseChangeDialogOpened, setPhaseChangeDialogOpened] = useState(false)
    const [phasesChangeButtonEnabled, setPhasesChangeButtonEnabled] = useState(false)
    const [phasesEnabled, setPhasesEnabled] = useState(false)
    const [numberOfPhaseTreatments, setNumberOfPhaseTreatments] = useState(record?.numberOfTreatments)
    const [selectedTreatmentType, setSelectedTreatmentType] = useState()

    const currentTreatmentProtocol = cache?.treatmentProtocolsCache?.find(tp => tp.id === record?.treatmentProtocolId)


    const isPatientType = (type) => {
        return patientType === type
    }

    const handleOUChange = ({ formContext, selection }) => {
        const { treatmentType, deviceSize } = formContext.getValues()
        if (treatmentType) {
            formContext.resetField('treatmentType')
            formContext.resetField('treatmentTypeVariant')
            setSelectedTreatmentType(null)
        }
        deviceSize && formContext.resetField('deviceSize')
        setOUData(selection)
    }

    useEffect(() => {
        if (Phases) {
            if (record?.phase) {
                setCurrentPhase(Phases.find(item => item.id === record.phase))
            } else {
                setCurrentPhase(initialPhase)
            }
        }
        if (!numberOfPhaseTreatments) {
            if (record?.stats) {
                const stats = JSON.parse(record.stats)
                setNumberOfPhaseTreatments(stats?.[record.phase]?.numberOfTreatments)
            } else {
                setNumberOfPhaseTreatments(record?.numberOfTreatments)
            }
        }
        if (record?.patientType) {
            setPatientType(record.patientType)
        }
        if (typeof record.medicalInfo === 'string') {
            try {
                record.medicalInfo = JSON.parse(record.medicalInfo)
            } catch { }
        }
        if (record.deviceSize && deviceType) {
            const deviceTypeId = deviceType?.find(item => item.id === record.deviceSize)?.typeId
            setChangesInDeviceOptions(changesInDeviceData?.[deviceTypeId])
        }
    }, [Phases, deviceType, record])

    useEffect(() => {
        setPatientType(ouData?.clinicType || 'RESEARCH')
    }, [ouData])

    return (
        <BQSimpleForm
            prepareForm={({ formData, setValue }) => {
                if (!formData.hasOwnProperty('recentAssessmentScores')) {
                    const assessmentScoresSorted = formData.assessmentScores
                    assessmentScoresSorted?.sort((a, b) => b.timestamp - a.timestamp)
                    const recentAssessmentScores = assessmentScoresSorted?.[0]
                    const previousAssessmentScores = assessmentScoresSorted?.[1]

                    setValue('recentAssessmentScores', recentAssessmentScores && { ...recentAssessmentScores } || {})
                    setValue('previousAssessmentScores', previousAssessmentScores)
                    if (formData.changesInDevice) {
                        const changesInDevice = []
                        let changesInDeviceOther = undefined
                        try {
                            JSON.parse(formData.changesInDevice)?.forEach(item => {
                                if (item.id === -1) {
                                    changesInDeviceOther = item.name
                                } else {
                                    changesInDevice.push(item.name)
                                }
                            })
                        } catch { }
                        setValue('changesInDevice', changesInDevice)
                        setValue('changesInDeviceOther', changesInDeviceOther)
                    }
                }
            }}
            toolbar={<BQToolbar type={subjectOrPatient} nameField="idNumber" confirmation={confirmationObject} conditionData={{ selectedTreatmentType }} />}>
            {displayVideoButton && <VideoMeetingButton />}
            <BQSideBySide>
                <>
                    <BQSection title={`${isPatientType('MARKET') ? 'Patient' : 'Subject'} Info`} readOnly={!isAdmin}>
                        {isSuperAdmin && <OUSelectInput label="Clinic"
                            onChange={handleOUChange}
                            onReady={({ selection }) => setOUData(selection)}
                        />}
                        <BQInput
                            label={`${isPatientType('MARKET') ? 'Patient name' : 'Subject ID'}`}
                            placeholder={`${isPatientType('MARKET') ? '' : 'Site ID-Subject ID (XXX-YY)'}`}
                            source="idNumber"
                            validate={isPatientType('MARKET') ? validate : validateIdNumber}
                            duplicates={duplicatesCheckData}
                            readOnly={!!record.idNumber && !(isSuperAdmin || isAdmin)} />
                        {isPatientType('MARKET') &&
                            <>
                                <BQInput label="Date of birth" source="dateOfBirth" type="date" validate={validateDate} />
                            </>
                        }
                        {!isPatientType('MARKET') && roleOtherThanRandomizer && <BQInput label="Year of birth" source="yearOfBirth" validate={validateYearOfBirth} minLength={4} maxLength={4} />}
                        {roleOtherThanRandomizer &&
                            <>
                                <BQDropDown
                                    label="Sex"
                                    source="gender"
                                    choices={gender}
                                    validate={validate}
                                    unsorted
                                />
                                <BQInput source="height"
                                    required={false}
                                    placeholder={ouData?.unitMetrics === 'METRIC' ? 'In cm (for example: 180)' : 'Feet’Inches (for example: 5’2)'}
                                    validate={value => validateHeight(value, ouData?.unitMetrics)}
                                    {...(isPatientType('MARKET') ? { maxLength: 3 } : {})}
                                />
                                <BQInput source="weight"
                                    required={false}
                                    placeholder={ouData?.unitMetrics === 'METRIC' ? 'In kg (for example: 65)' : 'Pounds (for example: 150)'}
                                    validate={validateNumber}
                                    min={0}
                                />
                                <NeuroInjurySection {...props} ouData={ouData} onSelect={selected => {
                                    setSelectedTreatmentType(selected)
                                }} />
                                <BQInput label="Date of event / diagnosis"
                                    source="dateOfEvent"
                                    type="date"
                                    validate={validateDate}
                                    required={false}
                                />
                                {!!deficits?.length && <BQAutoCompleteArrayInput source="medicalInfo.deficits" choices={deficits} readOnly={!isAdmin} />}
                                <BQDropDown source="caregiverRelation" label="Nature of caregiver relationship" choices={relation} unsorted />
                            </>
                        }
                    </BQSection>
                    {(roleOtherThanRandomizer || displayTreatmentShamComponents) && <BQSection title={`Treatment Info ${isEnglish ? '' : ' - ' + langName}`} readOnly={!isAdmin}>
                        {roleOtherThanRandomizer &&
                            <>
                                <BQDropDown
                                    source="deviceSize"
                                    label="Device type"
                                    choices={deviceType?.filter(item => !ouData?.deviceTypes || ouData?.deviceTypes?.includes(item.typeId))}
                                    defaultOnSingleOption
                                    unsorted
                                    validate={validate}
                                    onChange={(value) => {
                                        const typeId = value?.selection?.typeId
                                        setChangesInDeviceOptions(changesInDeviceData?.[typeId] || [])
                                    }}
                                />
                                <ChangesInDevice {...{ choices: changesInDeviceOptions }} />
                            </>
                        }

                        {(!currentPhase || currentPhase?.randomization) && displayTreatmentShamComponents && <BQDropDown source="controlPatient" label="Treatment/Sham group" choices={controlPatientOptions} unsorted readOnly={record.controlPatient !== 'UNSET'} validate={validate} />}

                        {roleOtherThanRandomizer &&
                            <>
                                {currentPhase && <PhaseSelector
                                    required
                                    readOnly={!phasesEnabled}
                                    defaultValue={currentPhase?.id}
                                    appSettings={appSettings}
                                    onChange={({ formContext, selection }) => handlePhaseChange({ formContext, selection, currentPhase, setCurrentPhase })}
                                />}
                                <TreatmentProtocolSelector {...{
                                    Phases,
                                    cache,
                                    currentPhase,
                                    readOnly: !isAdmin
                                }} />
                            </>
                        }
                    </BQSection>
                    }
                </>
                <>
                    {record?.id && Phases && (isAdmin || isOperator || isAuditor) && <BQSection
                        title={Phases && currentPhase?.nextPhase ? `Long term active stimulation group` : 'Treatment completion'}
                        readOnly={!isAdmin}
                        fullWidth
                    >
                        {currentPhase?.nextPhase && currentTreatmentProtocol?.nextPhaseMinSessions && (!record.phase || record.phase === initialPhase?.id) && !phasesEnabled &&
                            <>
                                <tr><td style={{ width: '100%', paddingTop: '24px' }}>
                                    <BQCheckbox
                                        id='stageMinDaysCompleted'
                                        label={`${subjectOrPatient} has completed day ${currentTreatmentProtocol?.nextPhaseMinDays} assessment`}
                                        readOnly={!isAdmin}
                                        onChange={({ newValue }) => {
                                            setPhasesChangeButtonEnabled(newValue && numberOfPhaseTreatments >= currentTreatmentProtocol?.nextPhaseMinSessions)
                                        }}
                                    /></td></tr>
                                <tr>
                                    <td style={{ padding: '16px 0' }}>
                                        <FormButton
                                            id='bt_proceedToNextStage'
                                            variant={phasesChangeButtonEnabled ? 'containedPrimary' : 'outlined'}
                                            disabled={!phasesChangeButtonEnabled || numberOfPhaseTreatments < currentTreatmentProtocol?.nextPhaseMinSessions}
                                            onClick={({ formContext }) => {
                                                setPhaseChangeDialogOpened(true)
                                            }}>
                                            {subjectOrPatient} PROCEEDS TO NEXT STAGE
                                        </FormButton>
                                    </td>
                                </tr>
                                <tr>
                                    <span id="lbl_phaseSessionsOutOfRequired">{`${numberOfPhaseTreatments || 0} sessions completed out of the ${currentTreatmentProtocol?.nextPhaseMinSessions} required for the next stage`}</span>
                                </tr>
                                <PhaseChangeConfirmationDialog
                                    nextPhaseMinDays={currentTreatmentProtocol?.nextPhaseMinDays}
                                    opened={phaseChangeDialogOpened}
                                    onConfirm={({ formContext }) => {
                                        setPhaseChangeDialogOpened(false)
                                        const nextPhase = Phases.find(item => item.id === currentPhase?.nextPhase)
                                        formContext.setValue('phase', nextPhase?.id, { shouldDirty: true, shouldTouch: true })
                                        formContext.setValue('treatmentProtocolId', null, { shouldDirty: true, shouldTouch: true })
                                        formContext.setValue(yearOfBirthVerification, undefined, { shouldDirty: true, shouldTouch: true })
                                        handlePhaseChange({ formContext, selection: nextPhase, currentPhase, setCurrentPhase })
                                        setPhasesEnabled(true)
                                        notify(`Clinical study stage was updated.`, { type: 'info' })
                                    }}
                                    handleClose={() => setPhaseChangeDialogOpened(false)}
                                />
                            </>
                            ||
                            <><br /><span id="lbl_lastClinicalStage">{subjectOrPatient} is allocated to the last clinical study stage</span><br /></>
                        }
                        <br />
                    </BQSection>}
                    {roleOtherThanRandomizer && <>
                        <AssessmentsComponent selectedTreatmentType={selectedTreatmentType} />
                        <BQSection title="Adjust exercise protocol" fullWidth>
                            <BQInput
                                type="number"
                                min={0}
                                max={5}
                                validate={validateNumber}
                                nullable
                                source="recentAssessmentScores.shoulderAbduction"
                                label="Shoulder abduction"
                                onChange={handleAssessmentScoresChange}
                                readOnly={isAssessmentScoresReadOnly}
                            />
                            <BQInput
                                type="number"
                                min={0}
                                max={5}
                                validate={validateNumber}
                                nullable
                                source="recentAssessmentScores.fingerExtension"
                                label="Finger extension"
                                onChange={handleAssessmentScoresChange}
                                readOnly={isAssessmentScoresReadOnly}
                            />
                            {!isAssessmentScoresReadOnly && <tr>
                                <td colspan="2">
                                    <SAFEComponent />
                                    &nbsp;&nbsp;
                                    <ApplyToProtocolButton {...props} />
                                </td>
                            </tr>}
                        </BQSection>
                        <PreviousAssessmentScores />
                    </>}
                </>


            </BQSideBySide>
            {
                roleOtherThanRandomizer &&
                <TreatmentProtocolComponent {...props} treatmentStagesContainer="currentTreatmentProtocol" >
                    <BQSection title="Exercise Protocol" fullWidth>
                        <br />
                        <BQSmartIterator source="currentTreatmentProtocol.treatmentStages">
                            <BQLabel label={({ value }) => getTranslatedName(value, langCode)} />
                            <BQLabel label="Stage Duration: [source] minutes" source="treatmentStageDuration" variant="body2" />
                            <BQLabel label={({ value }) => `Stage Type: ${choicesTreatmentStageType?.find(item => item.id === value)?.name}`} source="type" variant="body2" />
                            <BQSmartIterator source="exerciseCategories">
                                <div style={{ padding: '24px' }}>
                                    <ExerciseCategoryCheckbox {...props} langCode={langCode} readOnly={!canEditTreatment} />
                                </div>

                                <table style={{ width: '1200px', marginLeft: '48px', borderCollapse: 'collapse' }}>
                                    <tr style={headerStyle}>
                                        <td style={tableCellWidth(300)}><BQLabel style={titleStyle} label="Name" variant="body2" /></td>
                                        <td style={numberCell}><BQLabel style={titleStyle} label="Sets" variant="body2" /></td>
                                        <td style={numberCell}><BQLabel style={titleStyle} label="Repetitions" variant="body2" /></td>
                                        <td style={tableCellWidth(16)}></td>
                                        <td><BQLabel style={titleStyle} label="Progressions" variant="body2" /></td>
                                        <td style={tableCellWidth(160)}></td>
                                    </tr>

                                    <BQSmartIterator source="selectedExercises" >
                                        <ExerciseComponent canEditTreatment={canEditTreatment} />
                                    </BQSmartIterator>
                                </table>
                            </BQSmartIterator>
                        </BQSmartIterator>
                    </BQSection>
                </TreatmentProtocolComponent>
            }
        </BQSimpleForm >
    )
}

const PatientCreate = (props) => PatientMutate(true, props)

const PatientEdit = (props) => PatientMutate(false, props)

const PatientMutate = (isCreate, props) => {
    const { ouId } = bqAuthData
    const { language } = props
    const [duplicates, setDuplicates] = useState();
    const dataProvider = useContext(DataProviderContext);
    const [state, setState] = useState({ isLoadingDictionaries: true });
    useEffect(() => {
        getLists(dataProvider, true).then(data => setTimeout(() => setState({
            isLoadingDictionaries: false,
            cache: data
        }), 500))
    }, []);

    const ouResult = useBQGetOne({ resource: 'organizationalUnits', id: ouId })
    const { data: ouData } = ouResult

    const editorProps = { ...props, ...editorGlobalProps(), transform: (data) => patientDataTransform(data, setDuplicates, props) }
    if (isCreate) {
        editorProps.record = { controlPatient: 'UNSET' }
    }
    return ouData ? (
        <div>
            {isCreate ?
                (<Create {...editorProps}>
                    <PatientEditor {...props} language={language} redirect={bqCreateRedirection} {...state} duplicates={duplicates} ouData={ouData} />
                </Create>)
                :
                (<Edit {...editorProps} >
                    <PatientEditor {...props} language={language} redirect={bqEditRedirection} {...state} duplicates={duplicates} ouData={ouData} />
                </Edit>)
            }
        </div>
    ) : null
}

const patientDataTransform = async (data, setDuplicates, props) => {
    const { ouData } = props
    const duplicates = await getDuplicates('Patient', data, ['idNumber']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }

    const treatmentStages = data.currentTreatmentProtocol?.treatmentStages.map((stage, stageIndex) => ({
        index: stageIndex,
        treatmentStageId: stage.id,
        treatmentStageDuration: stage.treatmentStageDuration || 0,
        type: stage.type,
        deviceEnabled: null,
        exercisesCategories: stage.exerciseCategories.map((category, categoryIndex) => ({
            index: categoryIndex,
            exerciseCategoryId: category.exerciseCategoryId,
            selectedExercisesIndexed: category.selectedExercises.map((exercise, exerciseIndex) => ({
                index: exerciseIndex,
                isEnabled: !!exercise.isEnabled,
                exerciseId: exercise.exerciseId,
                numOfRepetitions: parseInt(exercise.numOfRepetitions) || null,
                numOfSets: parseInt(exercise.numOfSets) || null,
                secsInsteadOfReps: !!exercise.secsInsteadOfReps,
                progressionsList: exercise.progressionsList?.length && exercise.progressionsList || null
            }))
        }))
    }))
    setOU(data)

    if (data.changesInDevice || data.changesInDeviceOther) {
        if (typeof data.changesInDevice === 'string') {
            try {
                data.changesInDevice = JSON.parse(data.changesInDevice)
            }
            catch {
                data.changesInDevice = []
            }
        }
        data.changesInDevice = JSON.stringify(
            data.changesInDevice
                .map((item, index) => ({ id: index, name: item }))
                .concat(data.changesInDeviceOther ? [{ id: -1, name: data.changesInDeviceOther }] : [])
                .sort((a, b) => a.id - b.id)
        )
        delete data.changesInDeviceOther
    }

    data.medicalInfo = data.medicalInfo ? JSON.stringify(data.medicalInfo) : undefined
    data.unitMetrics = ouData?.unitMetrics

    delete data.treatmentProtocol;
    delete data.currentTreatmentProtocol;
    delete data.organizationalUnit_scan;
    delete data.assessmentScores
    delete data.previousAssessmentScores
    delete data.numberOfTreatments
    delete data.recentTreatmentDate
    delete data.stats
    delete data.deviceInfo
    delete data.deviceType
    delete data.idOrPassport
    delete data.phoneNumber
    delete data.undefined
    delete data.patientType_deletedAt

    Object.keys(data).filter(key => key.match(/PerPhase/)).forEach(key => delete data[key])

    if (data?.controlPatient?.toLowerCase() === 'set') {
        delete data.controlPatient
    }
    if (treatmentStages) {
        data.treatmentStages = treatmentStages;
    }
    return prepareFormData(data)
}

const NeuroInjurySection = (props) => {
    const record = useRecordContext()
    const formContext = useFormContext()
    const { appSettings, ouData, onSelect } = props
    const { treatmentTypes } = appSettings || {}
    const { useAll } = treatmentTypes || {}

    const [selectedType, setSelectedType] = useState(null)

    const handleSelection = (selection) => {
        onSelect(selection)
        setSelectedType(selection)
    }

    const treatmentChoices = treatmentTypes?.choices?.filter(item => useAll || ouData?.treatmentTypes?.includes(item.id))

    return treatmentTypes ? <>
        <BQDropDown
            source="treatmentType"
            unsorted
            defaultOnSingleOption
            validate={validate}
            label={treatmentTypes?.label}
            choices={treatmentChoices}
            readOnly={(record.idNumber && record.treatmentType)}
            onChange={({ selection }) => {
                formContext.setValue('treatmentTypeVariant', null, { shouldDirty: true, shouldTouch: true })
                handleSelection(selection)
            }}
            onReady={({ selection }) => {
                handleSelection(selection)
            }}
        />
        {selectedType?.variants && <BQDynamicInput
            type={selectedType.variantType}
            source="treatmentTypeVariant"
            label={selectedType.variantsLabel}
            choices={selectedType?.variants || [{ id: 'DEFAULT', name: 'Default' }]}
            readOnly={record.id && record.treatmentTypeVariant}
            validate={validate} />
        }
    </> : null
}

const PhaseChangeConfirmationDialog = ({ nextPhaseMinDays, onConfirm, handleClose, opened }) => {
    const { subjectOrPatient } = bqAuthData
    const formContext = useFormContext()
    return <DraggableDialog
        confirmText='Confirm'
        open={opened}
        title={`Confirm stage completion`}
        onConfirm={
            (e) => formContext.trigger(yearOfBirthVerification)
                .then((isValid) => isValid && onConfirm({ e, formContext }))
        }
        handleClose={handleClose}
        content={
            <div>
                Please make sure that the {subjectOrPatient.toLowerCase()} has completed day {nextPhaseMinDays} assessment
                <br />
                <br />
                To continue please type the {subjectOrPatient.toLowerCase()}'s year of birth:
                <table style={{ width: '100%' }}>
                    <BQInput source={yearOfBirthVerification} noLabel maxLength={4}
                        validate={() => {
                            const yearOfBirth = formContext.getValues().yearOfBirthVerification
                            return parseInt(yearOfBirth) === parseInt(formContext.getValues().yearOfBirth)
                                ? undefined
                                : `${subjectOrPatient}'s year of birth does not match`
                        }}
                    />
                </table>
            </div>
        } />
}

const TreatmentProtocolSelector = ({ Phases, cache, currentPhase, readOnly }) => {
    const { subjectOrPatient, isAdmin, isViewer } = bqAuthData
    const formContext = useFormContext()

    const [treatmentProtocolChoices, setTreatmentProtocolChoices] = useState([])
    const selectedTreatmentProtocol = treatmentProtocolChoices?.find(protocol => protocol.id === formContext.getValues().treatmentProtocolId)

    useEffect(() => {
        setTreatmentProtocolChoices(Phases
            ? (cache?.treatmentProtocolsCache
                ?.filter(tp => !currentPhase || tp.phase === currentPhase.id))
                ?.map(tp => {
                    const {
                        id,
                        name,
                        numberOfTreatments,
                        weeklySessionsLimit
                    } = tp
                    return {
                        id,
                        name,
                        numberOfTreatments,
                        weeklySessionsLimit
                    }
                })
            : cache?.treatmentProtocolsCache)
    }, [Phases, currentPhase, cache])

    return <>
        <BQDropDown
            label="Treatment protocol"
            source="treatmentProtocolId"
            choices={treatmentProtocolChoices}
            validate={validate}
            allowDuplicates={true}
            readOnly={readOnly}
            defaultOnSingleOption
        />
        <br />
        <TreatmentProtocolOverrides treatmentProtocol={selectedTreatmentProtocol} />
        {!isViewer &&
            <>
                <tr>
                    <td colspan="2"><hr /></td>
                </tr>
                <tr>
                    <td colspan="2">
                        <BQCheckbox label={`${subjectOrPatient} has completed treatment / discontinued`} source="participationConcluded" readOnly={!isAdmin} />
                    </td>
                </tr>
            </>}
    </>
}

const TreatmentProtocolOverrides = ({ treatmentProtocol }) => {
    const {
        name,
        numberOfTreatments,
        weeklySessionsLimit,
    } = treatmentProtocol || {}

    const { isAdmin } = bqAuthData
    return !!treatmentProtocol && <tr>
        <td colspan="2">
            <BQSection title="Adjust Treatment Protocol" fullWidth secondary readOnly={!isAdmin}>
                <BQInput source="targetNumberOfTreatments" label="Number of sessions" type='number' min={1} max={1000} placeholder={`${numberOfTreatments} treatments. Keep empty for protocol default`} />
                <BQInput source="weeklySessionsLimit" type='number' placeholder={`${weeklySessionsLimit} days. Keep empty for protocol default`} />
            </BQSection>
        </td>
    </tr>

}

const SAFEComponent = () => {
    const formContext = useFormContext()
    const values = formContext.getValues()
    return `SAFE: ${parseInt(values?.recentAssessmentScores?.shoulderAbduction || 0) + parseInt(values?.recentAssessmentScores?.fingerExtension || 0)}`
}

const AssessmentsComponent = ({ selectedTreatmentType }) => {
    const { isAdmin, isOperator, isViewer } = bqAuthData
    return <BQSection
        title="Assessments"
        visible={(isAdmin || isOperator || isViewer) && selectedTreatmentType?.medicalQuestions}
        readOnly={!isAdmin}
        fullWidth>
        {selectedTreatmentType?.medicalQuestions?.map(topics => {

            const questionsComponent = topics?.questions.map(question => <BQDynamicInput
                parentSource="medicalInfo"
                {...question}
            />)

            return topics.title ?
                <tr>
                    <td colspan="2">
                        {questionsComponent}
                    </td>
                </tr>
                :
                questionsComponent
        })}
    </BQSection >
}


const ChangesInDevice = ({ choices }) => {
    const { isAdmin } = bqAuthData
    const bqClasses = getFromCache('bqClasses')

    return <tr>
        <td className={bqClasses.inputLabel} style={{ verticalAlign: 'top', paddingTop: '24px' }}>
            Changes in device
        </td>
        <td>
            <table style={{ width: '100%' }}>
                <BQAutoCompleteArrayInput source="changesInDevice" choices={choices} readOnly={!isAdmin} noLabel />
                <tr>
                    <td colspan="2">
                        <span className={bqClasses.inputLabel}>Other - Specify below:</span>
                    </td>
                </tr>
                <BQInput noLabel source="changesInDeviceOther" multiline readOnly={!isAdmin} />
            </table>
        </td>
    </tr>
}

const PreviousAssessmentScores = (props) => {
    const formValues = useFormContext().getValues()
    const { previousAssessmentScores } = formValues
    if (previousAssessmentScores) {
        return <tr>
            <td colspan="2">
                <BQSection title={`Previous values: (${getDateTime(previousAssessmentScores.timestamp)})`} headerPadding={16}>
                    <p>
                        <b id="previous_values_shoulder_abduction_label">Shoulder abduction:</b> <span id="previous_values_shoulder_abduction_value">{previousAssessmentScores.shoulderAbduction}</span><br />
                        <b id="previous_values_finger_extension_label">Finger extension:</b> <span id="previous_values_finger_extension_value">{previousAssessmentScores.fingerExtension}</span><br />
                    </p>
                </BQSection>
            </td>
        </tr>
    }
    return null
}

const ApplyToProtocolButton = (props) => {
    const formContext = useFormContext()
    const formValues = formContext.getValues()

    return <Button id="apply_to_protocol_button" variant="outlined" style={{ padding: 8, margin: '8px 0px' }} onClick={() =>
        formValues.currentTreatmentProtocol?.treatmentStages?.forEach((stage, stageIndex) =>
            stage.exerciseCategories?.forEach((category, cagetoryIndex) =>
                category.selectedExercises?.forEach((exercise, exerciseIndex) => {
                    const cachedExercise = props.cache?.exercisesCache?.find(item => item.id === exercise.exerciseId)
                    if (cachedExercise?.useAssessmentScores) {
                        const source = `currentTreatmentProtocol.treatmentStages[${stageIndex}].exerciseCategories[${cagetoryIndex}].selectedExercises[${exerciseIndex}].isEnabled`

                        const patientShoulderAbduction = parseInt(formValues.recentAssessmentScores?.shoulderAbduction) - 1
                        const patientFingerExtension = parseInt(formValues.recentAssessmentScores?.fingerExtension) - 1

                        const isEnabled = cachedExercise.assessmentScoresMatrix?.[patientFingerExtension]?.[patientShoulderAbduction] === 1
                        formContext.setValue(source, isEnabled)
                    }
                })))
    }>Apply to protocol</Button>
}

const ExerciseComponent = (props) => {
    const bqClasses = getFromCache('bqClasses')
    const formContext = useFormContext()
    const inputValue = getValueByPath(formContext.getValues(), props.source)


    const { canEditTreatment, language } = props
    const { code: langCode } = language || {}

    const parseSource = (sourceName) => `${props.source}.${sourceName}`

    const undefinedOrNull = (val) => {
        return (Array.isArray(val) && val.length == 0) || val === undefined || val === null
    }
    const createChoices = (numbers) => numbers.map(num => {
        return { name: `${num}`, id: `${num}` }
    })

    const { numOfSets, numOfRepetitions, progressionsList, secsInsteadOfReps, availableProgressions } = inputValue || {}
    const secsRepetition = secsInsteadOfReps ? '(Seconds)' : ''
    const repsChoices = createChoices(secsInsteadOfReps ? [10, 20, 30, 40, 50, 60] : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15])
    const isDefault = undefinedOrNull(numOfSets) && undefinedOrNull(numOfRepetitions) && undefinedOrNull(progressionsList)

    return (
        <tr className={bqClasses.bqTableRow}>
            <td><div className={bqClasses.exerciseForPatientContent}><BQCheckbox variant={'caption'} source={parseSource('isEnabled')} label={({ record }) => getTranslatedName(record, langCode)} readOnly={!canEditTreatment} /></div></td>
            <td><div className={bqClasses.exerciseForPatientContent}><BQDropDown label="" source={parseSource('numOfSets')} borderless defaultValueSource={parseSource('orgNumOfSets')} choices={createChoices([1, 2, 3, 4, 5])} readOnly={!canEditTreatment} /></div></td>
            <td><div className={bqClasses.exerciseForPatientContent}><BQDropDown label="" source={parseSource('numOfRepetitions')} borderless defaultValueSource={parseSource('orgNumOfRepetitions')} choices={repsChoices} readOnly={!canEditTreatment} /></div></td>
            <td><div style={{ fontSize: '11px' }}>{secsRepetition}</div></td>
            <td>
                <div className={bqClasses.exerciseForPatientContent} style={canEditTreatment ? { paddingTop: '24px' } : {}} >
                    {canEditTreatment ?
                        <AutocompleteArrayInput
                            id={`${parseSource('progressionsList')}_input`}
                            choices={availableProgressions}
                            source={parseSource('progressionsList')}
                            label=""
                            optionValue="id"
                            variant="outlined"
                            optionText={(item) => getTranslatedName(item, langCode)}
                        />
                        :
                        <FunctionField label="" value={item => {
                            const data = getValueByPath(item, parseSource('progressionsList').replace('currentTreatmentProtocol.', ''))
                            return data?.map(element => availableProgressions.find(av => av.id === element)?.name)?.join(', ')
                        }} />
                    }
                </div>
            </td>
            <td onClick={() => {
                formContext.setValue(parseSource('progressionsList', undefined, { shouldDirty: true }))
                formContext.setValue(parseSource('numOfSets', undefined, { shouldDirty: true }))
                formContext.setValue(parseSource('numOfRepetitions', undefined, { shouldDirty: true }))
                formContext.resetField(parseSource('progressionsList'), { shouldDirty: true })
                formContext.resetField(parseSource('numOfSets'), { shouldDirty: true })
                formContext.resetField(parseSource('numOfRepetitions'), { shouldDirty: true })
            }}>
                <div className={bqClasses.exerciseForPatientContent} style={{ color: bqBlue, marginTop: '-8px' }}>
                    {!isDefault && canEditTreatment && <table style={{ cursor: 'pointer' }}><tr><td><RefreshRounded style={{ display: 'inline-block', transform: 'scaleX(-1)', fontSize: '24px' }} /></td><td><BQLabel variant="body2" label={'Switch to Default'} style={{ color: bqBlue }} /></td></tr></table>}
                </div>
            </td>
        </tr>
    )
}

const VideoMeetingButton = (props) => {
    const dataProvider = useContext(DataProviderContext);
    const record = useRecordContext()
    const [loadingMeetingData, setLoadingMeetingData] = useState(false)
    return <div style={{ position: 'fixed', left: '50%', top: '48px', zIndex: 99999 }}>
        <Button
            id="btStartMeeting"
            className="MuiButton-contained"
            style={{
                boxShadow: 'none'
            }}
            onClick={(e) => {
                if (loadingMeetingData) {
                    return
                }
                setLoadingMeetingData(true)
                dataProvider.getOne('zoomMeetingDataa', {
                    id: {
                        patientId: record?.id,
                        timezoneOffset: new Date().getTimezoneOffset() / -60.0
                    }
                })
                    .then(response => {
                        const meetingData = response?.data;
                        if (meetingData?.meetingNumber) {
                            startVideoMeeting({
                                sdkKey: meetingData.zoomSDKKey,
                                signature: meetingData.zoomSDKToken,
                                meetingNumber: meetingData.meetingNumber,
                                password: meetingData.meetingPassword,
                                userName: `${bqAuthData.name} (Operator)`,
                                zak: meetingData.zoomToken
                            }).finally(() => {
                                setLoadingMeetingData(false)
                            })
                        } else {
                            setLoadingMeetingData(false)
                            console.error(response)
                            alert('Could not initiate a video meeting. If this problem persists please contact BRAIN.Q support')
                        }
                    })
                    .catch(error => {
                        setLoadingMeetingData(false)
                        console.error(error)
                        alert('Could not initiate a video meeting. If this problem persists please contact BRAIN.Q support')
                    })
            }}>
            <div style={{ background: loadingMeetingData ? 'none' : 'url(\'./icons/video_meeting.svg\')', width: 24, height: 24 }}>
                {loadingMeetingData && <CircularProgress style={{ width: '16px', height: '16px', marginTop: '2px' }} />}
            </div>&nbsp;START A MEETING
        </Button>
    </div >
}

const handleAssessmentScoresChange = ({ formContext }) => formContext?.setValue('recentAssessmentScores.timestamp', null)

const handlePhaseChange = ({ formContext, selection, currentPhase, setCurrentPhase }) => {
    console.log(currentPhase)
    if (currentPhase.id !== selection.id) {
        formContext.setValue('treatmentProtocolId', null, { shouldDirty: true, shouldTouch: true })
        formContext.setValue('treatmentStages', [], { shouldDirty: true, shouldTouch: true })
        setCurrentPhase(selection)
    }
}

const titleStyle = {
    padding: '16px 16px 16px 8px',
    fontWeight: 'bold',
    color: 'rgba(0,0,0,0.8)'
}

const headerStyle = {
    fontSize: '16px',
    borderRight: '0px',
    borderLeft: '0px',
    boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.1)',
    borderSpacing: 0,
    verticalAlign: 'top'
}


const tableCellWidth = (width) => {
    return {
        maxWidth: `${width}px`,
        minWidth: `${width}px`,
        width: `${width}px`
    }
}
const numberCell = tableCellWidth(96)

export default BQCRUDBuilder({
    Create: PatientCreate,
    Edit: PatientEdit,
    List: PatientList,
})