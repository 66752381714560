import React, { createContext, useContext, useState } from 'react';

const BQUIContext = createContext(null);

export const BQUIHandler = ({ children, initialData = {} }) => {
    const [data, setData] = useState(initialData);

    const update = ({ source, label, id, name, confirmation }) => {
        setData((prevData) => ({
            ...prevData,
            [source]: { id, label, name, confirmation }
        }));
    };

    return (
        <BQUIContext.Provider value={{ data, setData, update }}>
            {children}
        </BQUIContext.Provider>
    );
};

export const useBQUIContext = () => {
    const context = useContext(BQUIContext);
    if (!context) {
        const error = new Error('useBQUIContext must be used within a BQDataHandler');
        console.warn(error);
    }
    return context;
};
