/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    TextField,
    Create,
    Edit,
    ReferenceArrayInput,
    DataProviderContext
} from 'react-admin';
import { getDuplicates, validate, validateDuplicates, validateNumber, validateNumberSet, validateString } from '../utils/validations';

import { BQAutoCompleteArrayInput, BQCheckbox, BQLabelWithInput, BQSimpleForm, createLabelFromSource, FunctionField, TranslationInput } from './Generic/bq-form-components';
import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { BQModelList, BQSection, BQToolbar } from './Generic/BQUI';
import { getTranslatedName, limitText } from '../utils/textUtils';
import { getLists } from './Generic/TreatmentProtocolExerciseData';

import { BQDropDown, BQInput } from './Generic/bq-input';
import { prepareFormData } from '../utils/transforms';
import BQCRUDBuilder from './Generic/BQCRUDComponent';
import GridSelector from './Generic/GridSelector';

const ExercisesList = (props) => {
    const { language } = props
    const { code: langCode, name: langName } = language || {}
    const isEnglish = !language?.code || language?.code?.toLowerCase() === 'en'
    return <BQModelList perPage="5">
        <TextField source="name" />
        {isEnglish ? <FunctionField source="tip" value={v => limitText(v)} /> : <FunctionField source="nameTranslations" label={`Name - ${langName}`} value={v => v?.find(t => t.code === langCode)?.text} />}
        {isEnglish && <FunctionField source="description" value={v => limitText(v)} />}
        <TextField source="numOfRepetitions" label={createLabelFromSource('numOfRepetitions')} />
        <TextField source="numOfSets" label={createLabelFromSource('numOfSets')} />
    </BQModelList>
}

const exerciseTransform = async (data, setDuplicates) => {
    const duplicates = await getDuplicates('Exercise', data, ['name', 'idInApp']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }

    delete data.exerciseCategory
    delete data.name_filter
    data.equipmentList = data.equipmentList || [];

    data.shoulderAbduction = data.shoulderAbduction ? fixNumberSet(data.shoulderAbduction) : data.shoulderAbduction
    data.fingerExtension = data.fingerExtension ? fixNumberSet(data.fingerExtension) : data.fingerExtension
    return prepareFormData(data)
}

const fixNumberSet = (value) => [...new Set(value.split(','))]?.map(v => parseInt(v)).filter(item => !isNaN(item)).sort((a, b) => a - b).join(',')

const fieldNamesToWatch = ['secsInsteadOfReps', 'useAssessmentScores']

const ExerciseEditor = props => {
    const { exerciseInApp, setType } = props.appSettings || {}

    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'Exercise',
        duplicates: props.duplicates
    }

    const { code: langCode, name: langName } = props.language || {}
    const isEnglish = !langCode || langCode.toLowerCase() === 'en'

    const [formWatch, setFormWatch] = React.useState({})
    const [bqForm, setBQForm] = React.useState({})
    React.useEffect(() => {
        const watchObject = {}
        fieldNamesToWatch.forEach((name, i) => watchObject[name] = formWatch[i])
        setBQForm(watchObject)
    }, [formWatch])

    const { secsInsteadOfReps, useAssessmentScores } = bqForm

    return (
        <BQSimpleForm
            toolbar={<BQToolbar type="Exercise" />}
            setWatch={{
                action: setFormWatch,
                name: fieldNamesToWatch
            }}
        >
            <BQSection title="Exercise Info" language={props.language}>
                <TranslationInput source="name" validate={validateString} duplicates={duplicatesCheckData} />
                <BQDropDown
                    label="Id in App"
                    source="idInApp"
                    choices={exerciseInApp}
                    validate={validateString}
                    duplicates={duplicatesCheckData}
                />

                <TranslationInput multiline source="tip" />
                <TranslationInput multiline source="description" validate={validateString} />

                <BQDropDown
                    label="Units"
                    source="secsInsteadOfReps"
                    choices={setType}
                    validate={validate}
                    translateChoice={false}
                    noAutocomplete={true}
                />

                <BQInput type="number" source="numOfRepetitions" label={`Number of ${secsInsteadOfReps ? 'seconds' : 'repetitions'}`} validate={validateNumber} min={1} />
                <BQInput type="number" source="numOfSets" validate={validateNumber} min={1} />

                <BQAutoCompleteArrayInput
                    source="progressionsList"
                    id="progressionsList"
                    label="Progressions"
                    optionValue="id"
                    variant="outlined"
                    choices={props?.cache?.progressionsCache}
                    optionText={(item) => getTranslatedName(item, langCode)}
                />

                <ReferenceArrayInput
                    source="equipmentList"
                    reference="equipments">
                    <BQAutoCompleteArrayInput
                        label="Equipment to use"
                        optionValue="id"
                        id="equipmentList"
                        optionText={(item) => getTranslatedName(item, langCode)}
                        variant="outlined"
                    />
                </ReferenceArrayInput>
                <BQCheckbox source="useAssessmentScores" label="By Assessment scores" />
                {!!useAssessmentScores &&
                    <>
                        <BQInput source="walkTest" validate={validateNumberSet} nullable min={0} max={5} />
                        <GridSelector
                            source="assessmentScoresMatrix"
                            rows={5}
                            columns={5}
                            topTitle="Shoulder abduction"
                            sideTitle="Finger extension"
                            sideTitleRotation={-90}
                            onSelectionChange={console.log} />
                    </>
                }
            </BQSection>
        </BQSimpleForm>

    )
}
const ExerciseMutation = (isCreate, props) => {
    const { language } = props
    const [duplicates, setDuplicates] = React.useState();

    const dataProvider = React.useContext(DataProviderContext);
    const [state, setState] = React.useState({ isLoadingDictionaries: true });
    React.useEffect(() => {
        getLists(dataProvider, true).then(data => setTimeout(() => setState({
            isLoadingDictionaries: false,
            cache: data
        }), 500))
    }, [dataProvider]);

    const mutationProps = { ...props, ...editorGlobalProps(), transform: (data) => exerciseTransform(data, setDuplicates) }
    return (
        <div>
            {isCreate ?
                (<Create {...mutationProps}>
                    <ExerciseEditor {...props} language={language} redirect={bqCreateRedirection} {...state} duplicates={duplicates} />
                </Create>)
                :
                (<Edit {...mutationProps} {...state} >
                    <ExerciseEditor {...props} language={language} redirect={bqEditRedirection} {...state} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

const ExercisesCreate = (props) => ExerciseMutation(true, props)

const ExercisesEdit = (props) => ExerciseMutation(false, props)

export default BQCRUDBuilder({
    Create: ExercisesCreate,
    Edit: ExercisesEdit,
    List: ExercisesList,
})