/* eslint-disable react/react-in-jsx-scope */
import * as React from 'react';

import Typography from '@material-ui/core/Typography';
import { DataProviderContext, useInput, useRecordContext, sanitizeFieldRestProps, SimpleForm, AutocompleteArrayInput, ListContextProvider, useList, Datagrid } from 'react-admin';
import { useFormContext, useWatch, Controller } from 'react-hook-form';
import { useBQStyles } from '../../themes';
import { useSelector } from 'react-redux';
import { Checkbox, SvgIcon } from '@material-ui/core';
import { BQIcons } from '../../symbols';
import { useDispatch } from 'react-redux';
import { languageSelected } from '../../redux/dispatchers/languageActions';
import { BQDropDown, BQInput } from './bq-input';
import { cloneChild } from './UITools';
import ReactJson from 'react-json-view'
import PhoneInput from 'react-phone-number-input';
import BQIterator from './BQIterator';
import { getFromCache, useGetAppSettings } from '../../utils/globals';
import { isNullOrEmpty } from '../../utils/textUtils';
import { BQUIHandler, useBQUIContext } from '../../utils/bqDataContext';

const getFormValue = (obj, path) => path?.split(/\.|\[|\]\.?/)?.filter(p => p)?.reduce((o, key) => (o || {})[key], obj)

export const getLabelValue = (props) => {
    const { label, source } = props || {}
    switch (typeof label) {
        case 'function':
            const lastIndexOfDot = !source?.endsWith(']') && source?.lastIndexOf('.') || -1
            const record = props.record || (source
                ? useInput({ ...props, label: null, source: lastIndexOfDot > 0 ? source.substring(0, lastIndexOfDot) : source })?.field?.value
                : useFormContext().getValues())

            const value = getFormValue(record, source)
            return label({ value, record }) || ' '
        default:
            return label || ' '
    }
}

export const createLabelFromSource = source => {
    if (!source) {
        return ''
    }
    const label = (source.match(/(?<=\.)\w+$/)?.[0] || source).match(/([a-z]+)|([A-Z][a-z]+)/g).join(' ').trim().toLowerCase().replace('num ', 'number ');
    return label.charAt(0).toUpperCase() + label.slice(1);
}

export const BQSvgIcon = props => (<SvgIcon {...props} viewBox="-4 -3 24 24" />)

const BQFormContext = ({ setWatch }) => {
    if (setWatch) {
        const { action, name } = setWatch
        action?.(useWatch({ name }))
    }
    return null
}

const PrepareFormData = ({ action }) => {
    if (action) {
        const { getValues, setValue } = useFormContext()
        action({ formData: { ...getValues() }, setValue })
    }
    return null
}

export const BQSimpleForm = (props) => {
    const { prepareForm } = props;

    return (
        <BQUIHandler>
            <SimpleForm
                autoComplete="off"
                warnWhenUnsavedChanges
                toolbar={null}
                {...props}
            >
                <PrepareFormData action={prepareForm} />
                <BQFormContext {...props} />
                {props.children}
            </SimpleForm >
        </BQUIHandler>
    )
}

export const BQLabelWithInput = (props) => {
    const { children, verticalAlign, onBlur, style, idClassName, ...rest } = props;
    const classes = getFromCache('bqClasses');

    return <tr className={classes.textInputContainer} onBlur={onBlur} tooltip="">
        <td className={classes.inputLabel}>
            <span style={style} id={`${idClassName}_label`}>
                {typeof children === 'string' ? children : children[0]}
            </span>
        </td>
        <td className={classes.textInput}>
            <span style={{ ...style, minWidth: '100%' }}>
                {typeof children === 'string' || !children[1] ? 'Second elemet is missing' : children[1]}
            </span>
        </td>
    </tr>
}

export const FunctionField = (props) => {
    const record = useRecordContext(props);

    const { id, source, value, title, variant, label, defaultValue, fromAppSettings, ...rest } = props

    const appSettings = fromAppSettings && useGetAppSettings()

    const titleValue = typeof title === 'function' && title(record) || title;
    const sourceValue = source ? (record && record[source]) : record;
    const enumValue = fromAppSettings && appSettings?.getById((typeof fromAppSettings === 'string' && fromAppSettings) || source, sourceValue)
    let calculatedValue = (typeof value === 'function' ? value(enumValue || sourceValue, record) : enumValue || sourceValue);
    const fieldId = `${id || source || label || ''}_field`.trim()
    if (fieldId === '_field') {
        console.warn('Field id is not set', { field: `${label || title}` })
    }
    if (!calculatedValue && parseInt(calculatedValue) !== 0) {
        calculatedValue = defaultValue || null
    }
    return <span id={fieldId} variant={variant || null} style={{ fontSize: '14px' }} title={titleValue}>{calculatedValue}</span>
}

export const DefaultValueField = (props) => {
    const { value, defaultValue } = props
    return <FunctionField {...props} value={(v, record) => value?.(v, record) ?? (v || defaultValue || 'N/A')} />
}

export const CustomList = ({ data, sortField, sortOrder, children, rowClick }) =>
    <ListContextProvider value={
        useList({
            data,
            ids: data?.map((item) => item.id),
            sort: { field: sortField || 'updatedAt', order: sortOrder || 'ASC' }
        })}>
        <Datagrid
            bulkActionButtons={false}
            rowClick={rowClick}
        >
            {children}
        </Datagrid>
    </ListContextProvider>

export const BQJsonField = (props) => {
    const { source, label, forceExpand, ...rest } = props
    const record = useRecordContext(props);
    let sourceValue = source ? (record && JSON.parse(record[source])) : record;
    const style = { fontSize: '14px' }

    if (source === 'dataDiff' && sourceValue) {
        const emptyKeys = sourceValue && Object.keys(sourceValue)?.filter(key => Object.keys(sourceValue?.[key])?.length === 0)
        emptyKeys?.forEach(key => {
            delete sourceValue[key]
        })
    }

    return sourceValue ?
        <ReactJson label={label} src={sourceValue} indentWidth={1} collapsed={!forceExpand} enableClipboard={false} displayDataTypes={false} style={style} sortKeys={true} />
        :
        <Typography variant={'caption'} {...sanitizeFieldRestProps(rest)} style={style}>{'N/A'}</Typography>
}

export const ActionField = (props) => {
    const { record, action } = props

    const children = React.Children.map(props.children, child => {
        return cloneChild(child)
    })

    return <div {...props} onClick={(e) => {
        e.stopPropagation()
        action(record)
    }}>
        {children}
    </div>
}

export const ConditionField = (props) => {
    const { record, hideIf, children } = props

    let conditionResult = true
    switch (typeof hideIf) {
        case 'function':
            conditionResult = !hideIf(record)
            break;
        default:
            conditionResult = !hideIf
            break;
    }

    return <div {...props} style={{ display: conditionResult ? 'block' : 'none' }}>
        {conditionResult && React.Children.map(children, child => {
            return cloneChild(child, props)
        })}
    </div>
}

export const LanguageSelection = (...props) => {
    const dp = React.useContext(DataProviderContext);
    const [list, setList] = React.useState([]);
    const dispatch = useDispatch()

    React.useEffect(() => {
        dp.getList('languages', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        }).then(({ data }) => {
            setList(data);
        })
        setTimeout(() => document.getElementById('language_input').blur(), 0)
    }, []);

    const onChange = ({ selection }) => {
        const { name, code } = selection || {}
        dispatch(languageSelected({ name, code }));
    }

    return <BQUIHandler>
        <BQDropDown id="language" {...props} onChange={onChange} optionValue="code" noLabel choices={list} />
    </BQUIHandler>
}

export const TranslationInput = (props) => {
    const { source } = props || {}
    const formContext = useFormContext()
    const record = formContext.getValues()
    const language = useSelector(state => state.langSelected.language);
    const langCode = language?.code?.toLowerCase()

    let translationSource = null
    if (langCode !== 'en') {
        const translationfieldSource = `${source}Translations`
        const fieldTreeArray = translationfieldSource.split(/\[(\d+)\].|\./).filter(item => item)
        const translationsField = getDataFromObject(record, fieldTreeArray, [])
        let indexInTranslation = translationsField?.findIndex(item => item.code === langCode)
        if (indexInTranslation === -1) {
            console.log('Translation not found', { source, translationsField, langCode })
            translationsField.push({ code: langCode, text: '' })
            indexInTranslation = 0
            formContext.setValue(translationfieldSource, translationsField, { shouldTouch: true, shouldDirty: true })
        }
        translationSource = `${source}Translations[${indexInTranslation}].text`
    }
    const sourceToUse = langCode === 'en' ? source : translationSource

    return <BQInput {...props} source={sourceToUse} label={createLabelFromSource(source)} />
}

const getDataFromObject = (data, treeArray, defaultValue) => {
    if (treeArray.length > 1) {
        return getDataFromObject(data[treeArray[0]], treeArray.slice(1), defaultValue)
    }
    if (!data[treeArray[0]]) {
        data[treeArray[0]] = defaultValue
    }
    return data[treeArray[0]]
}

export const BQAutoCompleteArrayInput = (props) => {

    const formContext = useFormContext()
    const bqClasses = getFromCache('bqClasses');
    const {
        label,
        id,
        source,
        choices,
        validate,
        noLabel,
        nullable,
        defaultOnSingleOption,
        readOnlyOnSingleOption = true,
        hideOnSingleOption,
        confirmation,
        onChange,
        readOnly
    } = props
    const bqUIContext = useBQUIContext()
    const idClassName = `${id || source || label}`;

    const singleValue = defaultOnSingleOption && choices?.length === 1 ? [choices[0].id] : null
    const isUsingSingleOption = !isNullOrEmpty(singleValue?.[0])
    if (isUsingSingleOption && !readOnly) {
        formContext.setValue(source, singleValue, { shouldTouch: true, shouldDirty: true })
    }

    React.useEffect(() => {
        const selectedValues = formContext.getValues()[source]
        if (Array.isArray(selectedValues)) {
            const ids = []
            const names = []
            selectedValues?.forEach(id => {
                const item = choices?.find(item => item.id === id)
                if (item) {
                    ids.push(id)
                    names.push(item.name)
                }
            })
            bqUIContext?.update({
                source,
                id: ids.join(', '),
                name: names.join(', '),
                ...(isUsingSingleOption ? {} : { confirmation })
            })
        }
    }, [choices])


    return isUsingSingleOption && hideOnSingleOption ? null : <tr className={bqClasses.textInputContainer}>
        {!noLabel && (
            <td className={bqClasses.inputLabel} style={{ paddingTop: '22px', verticalAlign: 'top' }}>
                <span className={`${idClassName}_label`} id={`${idClassName}_label`}>
                    {label || createLabelFromSource(source)} {validate && !nullable ? ' *' : ''}
                </span>
            </td>
        )}
        <td className={bqClasses.textInput}>
            {readOnly
                ? <div id={`${idClassName}_input`}>{Array.isArray(formContext?.getValues()?.[source]) ? <div style={{ paddingTop: '12px' }}>{formContext?.getValues()?.[source]?.join(', ')}</div> : ''}</div>
                : <span id={`${idClassName}_input`}>
                    <AutocompleteArrayInput
                        {...props}
                        disableClearable
                        onChange={e => {
                            formContext.trigger(source)
                            const ids = []
                            const names = []
                            e.forEach(id => {
                                const item = choices?.find(item => item.id === id)
                                ids.push(id)
                                names.push(item.name)
                            })
                            bqUIContext?.update({
                                source,
                                id: ids.join(', '),
                                name: names.join(', '),
                                ...(isUsingSingleOption ? {} : { confirmation })
                            })
                            onChange?.(e)
                        }}
                        disabled={readOnlyOnSingleOption && !isNullOrEmpty(singleValue?.[0]) || readOnly}
                    />
                </span>}
        </td>
    </tr >
}

export const BQLabel = props => {
    const { id, source, variant, style } = props
    const formValues = useFormContext().getValues()
    const labelValue = getLabelValue({ ...props, record: formValues }).toString() || ''
    const input = useInput({ ...props, source: source || '' })
    const inputValue = getFormValue(formValues, input.field.name)
    let value = props.value || inputValue
    return <Typography id={id || source} variant={variant || 'h6'} style={{ ...(style || {}) }}>{labelValue && labelValue?.replace(/\[source\]/ig, value) || value}</Typography>
}

export const useCheckboxInputData = (props) => {
    const { source, type, defaultValue, ...rest } = props || {}
    const watcher = source && useWatch({ name: props.source })
    return watcher
}

export const BQCheckbox = ({ source, control, rules, validate, value, onChange: baseOnChange, ...rest }) => {
    const formContext = useFormContext()
    const localOnChange = (value, onChange) => {
        !source && setTranslatedValue(value)
        onChange(value)
        baseOnChange?.({ newValue: value, formContext })
        source && validate && formContext.trigger(source)
    }

    const [translatedValue, setTranslatedValue] = React.useState(source ? value : false)


    return (
        <Controller
            name={source || ''}
            control={formContext.control}
            rules={{ validate: (v) => validate?.(v, source, formContext) }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                return <BQCheckboxInput {...rest} source={source} value={source ? value : translatedValue} onChange={(e) => localOnChange(e, onChange)} error={error} />
            }}
        />
    );
}

export const BQCheckboxInput = (props) => {
    const {
        value,
        onChange,
        source,
        id,
        noLabel,
        readOnly,
        style,
        indeterminate,
        variant,
        error,
    } = props;

    const classes = getFromCache('bqClasses');
    const idClassName = id || source;

    const labelValue = getLabelValue(props, value);
    const labelToUse =
        labelValue !== undefined ? labelValue : createLabelFromSource(source);

    const handleChange = (e) => {
        e.stopPropagation()
        if (readOnly) return;
        onChange(!value);
    };

    const CheckBoxIcon = indeterminate
        ? BQIcons.checkbox_intermediate
        : value
            ? BQIcons.checkbox_on
            : BQIcons.checkbox_off;

    return (
        <label
            onClick={handleChange}
            type="checkbox"
            className={`${classes.checkboxContainer} ${!readOnly && classes.pointer}`}
            style={{ ...(style || {}) }}
            id={`${idClassName}_checkbox`}
            value={value}
        >
            {error && <div className={classes.checkboxError}></div>}
            <CheckBoxIcon />
            {!noLabel && (
                <Typography
                    variant={variant || 'content'}
                    className={classes.checkboxLabel}
                    id={`${idClassName}_label`}
                >
                    {labelToUse}
                </Typography>
            )}
        </label>
    );
};



// export const BQCheckboxOld = props => {
//     const formContext = useFormContext()
//     const selfValue = !!props.value
//     const classes = getFromCache('bqClasses')

//     const { id, source, indeterminate, style, readOnly, forceEdit, variant, noLabel } = props
//     const value = source ? useCheckboxInputData({ ...props }) : selfValue;

//     const idClassName = id || source
//     const lableValue = getLabelValue(props, value)

//     const labelToUse = lableValue || (typeof lableValue !== 'undefined' ? lableValue : createLabelFromSource(source));

//     const checkboxProps = { ...props }
//     if (readOnly && !forceEdit) {
//         delete checkboxProps.onChange
//     }
//     const { onChange: baseOnChange } = checkboxProps

//     const localOnChange = ({ event, newValue }) => {
//         if (readOnly) {
//             event.preventDefault()
//             event.stopPropagation()
//             return
//         }
//         if (source) {
//             formContext.setValue(source, newValue, { shouldTouch: true, shouldDirty: true })
//         }
//         baseOnChange?.({ event, newValue, formContext })
//     }

//     return (
//         <table onClick={((e) => {
//             localOnChange({ event: e, newValue: !value })
//         })} className={!readOnly && classes.pointer} style={{ ...(style || {}) }}>
//             <tr title="">
//                 <td className={classes.checkbox} >
//                     <Checkbox
//                         disabled={readOnly}
//                         id={`${idClassName}_checkbox`}
//                         checkedIcon={<BQSvgIcon component={BQIcons.checkbox_on} />}
//                         value={value}
//                         checked={value}
//                         indeterminate={indeterminate}
//                         icon={<BQSvgIcon component={BQIcons.checkbox_off} />}
//                         indeterminateIcon={<BQSvgIcon component={BQIcons.checkbox_intermediate} />}
//                     />
//                 </td>
//                 {!noLabel && <td>
//                     {<Typography variant={variant || 'content'} className={!variant && classes.index}
//                         id={`${idClassName}_label`}
//                         helperText={null}>{labelToUse}</Typography>}
//                 </td>}
//             </tr>
//         </table>
//     )
// }

const setSourceToChild = (child, parentSource) => {
    const childSource = child?.props?.source;
    const source = `${parentSource}${childSource ? `.${childSource}` : ''}`;

    // If the child is one of our iterator components, simply clone it with the new source.
    if (child.type.name === BQSmartIterator.name || child.type.name === BQSimpleIterator.name) {
        return React.cloneElement(child, { source });
    }

    let childChildren = null;
    if (Array.isArray(child?.props?.children)) {
        // When mapping, you can also rely on the child’s own key (if provided) or use the index.
        childChildren = child.props.children.map((grandchild, index) => {
            if (React.isValidElement(grandchild)) {
                return setSourceToChild(grandchild, source);
            } else {
                return grandchild;
            }
        });
    } else if (React.isValidElement(child?.props?.children)) {
        childChildren = setSourceToChild(child.props.children, source);
    } else {
        childChildren = child?.props?.children;
    }
    return React.cloneElement(child, { source, children: childChildren });
};

export const BQSmartIterator = (props) => {
    const formContext = useFormContext();
    const input = useInput({ source: props.source });
    const inputValue = getFormValue(formContext.getValues(), input.field.name);
    const childrenArray = [];

    if (Array.isArray(inputValue)) {
        // For each item in the input array, clone each child with an updated source and add a key.
        inputValue.forEach((_, index) => {
            React.Children.forEach(props.children, (child, childIndex) => {
                if (React.isValidElement(child)) {
                    const clonedChild = setSourceToChild(child, `${props.source}[${index}]`);
                    childrenArray.push(
                        React.cloneElement(clonedChild, {
                            key: `${props.source}[${index}]-${child.key || childIndex}`,
                        })
                    );
                } else {
                    childrenArray.push(child);
                }
            });
        });
    } else if (inputValue) {
        // If inputValue is a single value (not an array), clone each child with the original source.
        React.Children.forEach(props.children, (child, childIndex) => {
            if (React.isValidElement(child)) {
                const clonedChild = setSourceToChild(child, props.source);
                childrenArray.push(
                    React.cloneElement(clonedChild, {
                        key: child.key || childIndex,
                    })
                );
            } else {
                childrenArray.push(child);
            }
        });
    }
    return <>{childrenArray}</>;
};


export const BQSimpleIterator = (props) => <BQIterator {...props} disableRemove disableAdd disableReordering noNumbering />

export const BQPhoneNumber = (props) => {
    const [value, setValue] = React.useState()
    const inputValue = useInput(props)?.input?.value
    return (
        <PhoneInput
            placeholder="Enter phone number"
            value={inputValue}
            onChange={setValue} />
    )
}
