/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    TextField,
    Create,
    Edit,
    useRecordContext,
} from 'react-admin';
import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { getDuplicates, validate, validateDuplicates, validateNoSpaces, validateOU } from '../utils/validations';
import { BQDropDown, BQInput } from './Generic/bq-input';
import { BQModelList, BQSection, BQToolbar } from './Generic/BQUI';
import { prepareFormData } from '../utils/transforms';
import BQCRUDBuilder from './Generic/BQCRUDComponent';
import { BQAutoCompleteArrayInput, BQSimpleForm, FunctionField } from './Generic/bq-form-components';
import { bqAuthData } from '../utils/bq-auth';

const organizationTransform = async (data, setDuplicates) => {
    while (data?.organizationalUnit?.endsWith('.')) {
        data.name = data.organizationalUnit.substring(0, data.organizationalUnit.length - 1);
    }
    const duplicates = await getDuplicates('OrganizationalUnit', data, ['organizationalUnit']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }
    return prepareFormData(data)
};

const OrganizationList = (props) => {
    const { appSettings } = props
    const { clinicTypes } = appSettings || {}
    return (
        <BQModelList showDates nameField="organizationalUnit">
            <TextField source="organizationalUnit" label="Clinic ID" />
            <TextField source="facilityName" />
            <FunctionField label="Clinic type" value={v => clinicTypes?.find(item => item.id === v?.clinicType)?.name} />
        </BQModelList>
    )
}

const confirmationObject = [
    {
        title: `The clinic [organizationalUnit] was set with the following definitions:`,
        text: `{confirmationElements}\n\nPlease verify the definitions above. Are you sure you want to save the clinic?`,
        condition: () => true
    }
]

const OrganizationEditor = (props) => {
    const { ou, isSuperAdmin } = bqAuthData
    const { appSettings } = props
    const { clinicTypes, deviceOptions, treatmentTypes, unitMatrics } = appSettings || {}
    const { useAll } = treatmentTypes ?? {}
    const [selectedClinicType, setSelectedClinicType] = React.useState()
    const [treatmentTypesChoices, setTreatmentTypesChoices] = React.useState()

    const initialOU = ou.match(/b[\W]{0,}r[\W]{0,}a[\W]{0,}i[\W]{0,}n[\W]{0,}q/ig)[0]
    const record = useRecordContext()
    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'A clinic',
        duplicates: props.duplicates
    }

    const handleClinicTypeChange = (selection, formContext) => {
        if (!selection) {
            formContext?.setValue('treatmentTypes', null)
        }
        setSelectedClinicType(selection)
    }

    React.useEffect(() => {
        setTreatmentTypesChoices(treatmentTypes?.choices?.filter(item => item.clinicTypes?.includes(selectedClinicType?.id)))
    }, [selectedClinicType])

    return <BQSimpleForm
        toolbar={<BQToolbar type="Clinic" nameField="organizationalUnit" confirmation={isSuperAdmin && confirmationObject} />}
    >
        <BQSection title="Clinic Info" >
            <BQInput source="organizationalUnit" placeholder={`${initialOU}.SiteName`} label="Clinic ID"
                readOnly={!!record?.id}
                validate={[validateNoSpaces, validateOU]}
                duplicates={duplicatesCheckData}
            />
            <BQInput source="facilityName" />

            <BQDropDown
                label="Clinic type"
                source="clinicType"
                validate={validate}
                choices={clinicTypes}
                variant="outlined"
                defaultOnSingleOption
                hideOnSingleOption
                confirmation="Clinic Type"
                onChange={({ formContext, selection }) => handleClinicTypeChange(selection, formContext)}
                onReady={({ formContext, selection }) => handleClinicTypeChange(selection, formContext)}
            />

            {!useAll && <BQAutoCompleteArrayInput
                label={treatmentTypes?.label}
                source="treatmentTypes"
                validate={validate}
                choices={treatmentTypesChoices}
                variant="outlined"
                defaultOnSingleOption
                hideOnSingleOption
                confirmation="Neurological injury types"
                disabled={!treatmentTypesChoices}
            />}

            <BQAutoCompleteArrayInput
                label="Device types"
                source="deviceTypes"
                validate={validate}
                choices={deviceOptions}
                defaultOnSingleOption
                hideOnSingleOption
                confirmation="Device types"
                variant="outlined"
            />

            <BQDropDown
                source="unitMetrics"
                choices={unitMatrics}
                validate={validate}
                defaultOnSingleOption
                hideOnSingleOption
                confirmation="Unit Metrics"
                variant="outlined"
            />

        </BQSection>
    </BQSimpleForm>
}


const OrganizationMutation = (isCreate, props) => {
    const [duplicates, setDuplicates] = React.useState();
    const mutationProps = { ...props, ...editorGlobalProps(), transform: (data) => organizationTransform(data, setDuplicates) }

    return (
        <div>
            {isCreate ?
                (<Create {...mutationProps}>
                    <OrganizationEditor redirect={bqCreateRedirection} duplicates={duplicates} appSettings={props.appSettings} />
                </Create>)
                :
                (<Edit {...mutationProps}>
                    <OrganizationEditor redirect={bqEditRedirection} duplicates={duplicates} appSettings={props.appSettings} />
                </Edit>)
            }
        </div>
    )
}

const OrganizationCreate = (props) => OrganizationMutation(true, props)

const OrganizationEdit = (props) => OrganizationMutation(false, props)

export default BQCRUDBuilder({
    Create: OrganizationCreate,
    Edit: OrganizationEdit,
    List: OrganizationList
})

