import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DraggableDialog = (props) => {
    const {
        open,
        handleClose,
        title,
        content,
        cancelText,
        confirmText,
        onConfirm,
        onCancel,
        disableCancel,
    } = props

    const dialogConfirmed = (e) => {
        e.preventDefault()
        onConfirm?.(e);
    }

    const dialogCanceled = (e) => {
        e.preventDefault()
        onCancel && onCancel(e);
        handleClose?.(e);
    }

    return (
        <div>
            <Dialog
                onClick={e => e.stopPropagation()}
                open={open}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {title || 'Dialog Title'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        {...(
                            typeof content === 'string' ?
                                {
                                    dangerouslySetInnerHTML: {
                                        __html: content
                                            ?.replace(/<b>/g, '{{b}}')
                                            ?.replace(/<\/b>/g, '{{/b}}')
                                            ?.replace(/</g, '&lt')?.replace(/>/g, '&gt')
                                            ?.replace(/\n/g, '<br/>')
                                            ?.replaceAll('{{b}}', '<b>')
                                            ?.replaceAll('{{/b}}', '</b>')
                                    }
                                } : {}
                        )}>
                        {typeof content === 'string' ? null : content || 'Dialog Content'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {!disableCancel && <Button id="cancel_button" onClick={dialogCanceled} color="primary">
                        {cancelText || 'Cancel'}
                    </Button>}
                    <Button id="confirm_button" onClick={dialogConfirmed} color="primary">
                        {confirmText || 'Yes'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DraggableDialog;
