import { useEffect, useState } from "react"
import { useBQGetOne } from "../components/Generic/hooks"
import { clearObject, resetGlobals } from "./globals"

export const bqAuthData = {}

export const useBQAuthData = (authSession) => {
    const autheticatedUser = authSession?.tokens?.idToken?.payload
    const claims = autheticatedUser ? JSON.parse(autheticatedUser['cognito:groups']) : {}

    const [authData, setAuthData] = useState({})

    useEffect(() => {
        if (autheticatedUser) {
            const authObject = {
                id: autheticatedUser.sub,
                email: autheticatedUser.email,
                name: autheticatedUser.name || autheticatedUser.email,
                role: claims.role,
                ou: claims.organizationalUnit,
                ouId: claims.organizationalUnitId,
                clinicType: claims.clinicType,
                unitMetrics: claims.unitMetrics,
                isMarket: claims.clinicType === 'MARKET',
                isResearch: claims.clinicType === 'RESEARCH',

                isAdmin: !!claims.role.some(role => role.match(/administrator/i)),
                isSuperAdmin: !!claims.role.some(role => role.match(/super administrator/i)),
                isRandomizer: !!claims.role.some(role => role.match(/random/i)),
                isAuditor: !!claims.role.some(role => role.match(/auditor/i)),
                isOperator: !!(claims.role.some(role => role.match(/operator/i))),
                isViewer: !!(claims.role.some(role => role.match(/viewer/i))),
                subjectOrPatient: claims.clinicType === 'MARKET' ? 'Patient' : 'Subject',
                isInventoryAdmin: !!claims.role.some(item => item.match(/warehouse manager/i)),
                userAllowed: claims.role.some(item => item?.trim() && !item.match(/caregiver/i))
            }
            authObject.isInventory = authData.isInventoryAdmin || !!claims.role.some(item => item.match(/inventory/i))
            Object.keys(authObject).forEach(key => {
                bqAuthData[key] = authObject[key]
            })
            setAuthData(authObject)
        }
    }, [autheticatedUser])

    return authData
}

export const clearBQAuthData = () => {
    clearObject(bqAuthData)
    resetGlobals()
    clearFormsData()
}

export const clearFormsData = (expandedOnly) => {
    const keysToRemove = {};
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.indexOf('RaStore') != -1) {
            if (expandedOnly) {
                if (key.indexOf('expanded') != -1) {
                    keysToRemove[key] = true;
                }
            } else {
                keysToRemove[key] = true;
            }
        }
    }
    for (let key in keysToRemove) {
        localStorage.removeItem(key);
    }
}